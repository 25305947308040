<template>
    <div>
        <div class="d-flex">
            <v-spacer />
            <v-btn
                dark
                color="purple darken-2"
                :loading="loading.export"
                @click.prevent.stop="exportToXlsx"
            >
                Экспорт в xlsx
            </v-btn>
        </div>

        <template v-for="(groupsInParallel, grade) in reportData">
            <h4 :key="`header_${grade}`" class="mb-2">Классы {{ grade }}</h4>

            <div :key="`results_${grade}`" class="d-flex flex-wrap">
                <v-card
                    v-for="group in groupsInParallel"
                    :key="`card_${group.group_id}`"
                    class="pa-5 mb-5 mr-5"
                    style="width: max-content"
                >
                    <strong class="d-flex mb-4">Оценки {{ group.name }}</strong>
                    <div class="d-flex flex-column justify-center">
                        <div class="d-flex align-center w-100">
                            <h5>Ученик</h5>
                            <v-spacer />
                            <h5>Оценка</h5>
                        </div>
                        <span
                            v-for="student in group.items"
                            :key="student.student_id"
                            class="d-flex align-center"
                        >
                            <span>{{ student.name }}</span>
                            <v-spacer />
                            <value-cell
                                :value="student.value"
                                :compare-value="student.prevValue"
                                class="ml-2"
                            />
                        </span>
                        <span class="d-flex align-center">
                            <strong>Итого (средняя)</strong>
                            <v-spacer />
                            <value-cell
                                :value="group.avarage.value"
                                :compare-value="group.avarage.prevValue"
                                class="ml-2"
                            />
                        </span>
                    </div>
                </v-card>
            </div>
        </template>
    </div>
</template>

<script>
import ValueCell from './ValueCell.vue'
import ExcelHelper from '@/helpers/ExcelHelper'
import { saveDataAsFile } from '@/helpers/File'

export default {
    components: {
        ValueCell
    },
    props: {
        results: { type: Array, required: true },
        prevResults: { type: Array, required: true },
        exportFileName: { type: String, default: 'Статистика по предметам' }
    },
    data () {
        return {
            loading: {
                export: false
            }
        }
    },
    computed: {
        gradesInResults () {
            return Array.from(new Set(this.results.map(group => group.grade)))
        },
        reportData () {
            const result = {}

            this.gradesInResults.forEach(grade => {
                if (!Array.isArray(result[grade]))
                    result[grade] = []

                const groups = _.cloneDeep(this.results)
                                    .filter(group => group.grade === grade)
                                    .map(group => {
                                        group.items.forEach(student => {
                                            student.value = student.scores.length ? this.getAvarageScore(student.scores) : 'Н'
                                            student.prevValue = this.getAvarageScore(this.getScoresOfStudentFromResults(this.prevResults, group.group_id, student.student_id))
                                        })

                                        group.avarage = {
                                            value: this.getAvarageScore(group.items.map(student => student.scores).flat()),
                                            prevValue: this.getAvarageScore(this.getScoresOfGroupFromResults(this.prevResults, group.group_id))
                                        }

                                        return group
                                    })
                groups.sort((a, b) => {
                            if (a.name < b.name) return -1
                            if (a.name > b.name) return 1
                            return 0
                        })
                result[grade].push(...groups)
            })
            return result
        }
    },
    methods: {
        getAvarageScore (scores) {
            const sum = scores.reduce((a, b) => a + b, 0)
            const avg = (sum / scores.length) || 0
            return parseFloat(avg.toFixed(2))
        },
        getScoresOfStudentFromResults (prevResults, group_id, student_id) {
            return prevResults.find(group => group.group_id === group_id)?.items
                    .find(student => student.student_id === student_id)?.scores || []
        },
        getScoresOfGroupFromResults (prevResults, group_id) {
            return prevResults.find(group => group.group_id === group_id)?.items.map(student => student.scores).flat() || []
        },
        async exportToXlsx () {
            
            this.loading.export = true

            try {
                const workbook = ExcelHelper.createWorkbook()
                const worksheet = ExcelHelper.createWorksheet(workbook, 'Статистика по классу')

                Object.keys(this.reportData).forEach((grade, k) => {
                    
                    this.reportData[grade].forEach((group, index) => {
                        
                        if (index === 0) {
                            worksheet.addRow([`Классы ${grade}`])
                            worksheet.lastRow.eachCell({}, function(cell) {
                                cell.font = { bold: true }
                            })
                            worksheet.addRow()
                        }
                        worksheet.addRow([`Оценки ${group.name}`])
                        worksheet.addRow(['№', 'Ученик', 'Оценка'])
                        group.items.forEach((student, index) => {
                            worksheet.addRow([index + 1, student.name, student.value])
                        })

                        worksheet.addRow(['Итого (средняя)', '', group.avarage.value])

                        if ((Object.keys(this.reportData).length - 1) > k || (this.reportData[grade].length - 1) > index)
                            worksheet.addRow()
                    })
                })

                worksheet.columns.forEach(column => {
                    column.width = 20
                    
                    column.eachCell({ includeEmpty: true }, function(cell) {
                        cell.border = {
                            top: {style: 'thin'},
                            left: {style: 'thin'},
                            bottom: {style: 'thin'},
                            right: {style: 'thin'}
                        }
                    })
                })

                const xlsxData = await workbook.xlsx.writeBuffer()
                const blob = typeof xlsxData === Blob ? xlsxData : new Blob([xlsxData], { type: 'application/xlsx' })
                saveDataAsFile(
                    blob,
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    `${this.exportFileName}.xlsx`
                )
            } catch (e) {
                console.error(e)
                this.$root.$emit('snack-bar', { text: 'Формирование файла прервано ошибкой' })
            } finally {
                this.loading.export = false
            }
        }
    }
}
</script>