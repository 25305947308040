<template>
    <div>
        <div class="d-flex">
            <v-spacer />
            <v-btn
                dark
                color="purple darken-2"
                :loading="loading.export"
                @click.prevent.stop="exportToXlsx"
            >
                Экспорт в xlsx
            </v-btn>
        </div>

        <template v-for="(groupsInParallel, grade) in reportData">
            <h4 :key="`header_${grade}`" class="mb-2">Классы {{ grade }}</h4>

            <div :key="`results_${grade}`" class="d-flex flex-wrap">
                <v-card
                    v-for="group in groupsInParallel"
                    :key="`card_${group.group_id}`"
                    class="pa-5 mb-5 mr-5"
                    style="width: max-content"
                >
                    <strong class="d-flex mb-4">Степень освоения тем классом {{ group.name }}</strong>
                    <v-data-table
                        dense
                        :items-per-page="-1"
                        hide-default-footer
                        calculate-widths
                        :headers="group.headers"
                        :items="group.items"
                        item-key="id"
                        class="elevation-1"
                    >
                        <template
                            v-for="(theme, ti) in allThemesByGrades[grade]"
                            #[`header.${theme}`]
                        >
                            <span :key="ti" :title="theme">{{limitStringLength(theme)}}</span>
                        </template>
                        <template #[`item.name`]="{ item }">
                            <strong v-if="item.final">{{ item.name }}</strong>
                            <span v-else>{{ item.name }}</span>
                        </template>
                        <template
                            v-for="(theme, ti) in allThemesByGrades[grade]"
                            #[`item.${theme}`]="{ item }"
                        >
                            <value-cell
                                v-if="item[theme] && !item.final"
                                :key="ti"
                                :value="item[theme].value"
                                :compare-value="item[theme].prevValue"
                                :postfix="item[theme].value === 'Н' ? '' : '%'"
                            />
                            <value-cell
                                v-else-if="item[theme] && item.final"
                                :key="ti"
                                :value="item[theme].value"
                                :compare-value="item[theme].prevValue"
                                postfix="%"
                            />
                        </template> 
                    </v-data-table>
                </v-card>
            </div>
        </template>
    </div>
</template>

<script>
import ValueCell from './ValueCell.vue'
import StringHelper from '@/helpers/StringHelper'
import ExcelHelper from '@/helpers/ExcelHelper'
import { saveDataAsFile } from '@/helpers/File'

export default {
    components: {
        ValueCell
    },
    props: {
        results: { type: Array, required: true },
        prevResults: { type: Array, required: true },
        exportFileName: { type: String, default: 'Сводная по темам в классе' }
    },
    data () {
        return {
            loading: {
                export: false
            }
        }
    },
    computed: {
        gradesInResults () {
            return Array.from(new Set(this.results.map(group => group.grade)))
        },
        allThemes () {
            return Array.from(new Set(this.results.map(group => group.items.map(student => student.scores.map(score => score.theme)).flat()).flat()))
        },
        allThemesByGrades () {
            const themesByGrade = {}

            this.gradesInResults.forEach(grade => {

                const themes = this.results
                                .filter(group => group.grade === grade)
                                .map(group => group.items.map(student => student.scores.map(score => score.theme)).flat()).flat()
                themesByGrade[grade] = Array.from(new Set(themes))
            })
            return themesByGrade
        },
        reportData () {
            const result = {}

            this.gradesInResults.forEach(grade => {
                if (!Array.isArray(result[grade]))
                    result[grade] = []

                const groups = _.cloneDeep(this.results)
                                    .filter(group => group.grade === grade)
                                    .map(group => {
                                        return {
                                            ...group,
                                            headers: this.getHeaders(group),
                                            items: this.getModels(group)
                                        }
                                    })
                result[grade].push(...this.sortBy(groups, 'name'))
            })
            return result
        }
    },
    methods: {
        getHeaders (group) {
            const themes = this.allThemesByGrades[group.grade]
            return [
                { text: 'Ученик', value: 'name' },
                ...themes.map(item => ({
                                    text: item,
                                    value: item
                                }))
            ]
        },
        getModels(group) {
            if (!group.items.length) return []
            
            const models = group.items.map(student => {
                const fields = {}

                this.allThemesByGrades[group.grade].forEach(theme => {
                    const themeScores = student.scores.find(item => item.theme === theme)?.scores || []
                    fields[theme] = {
                        value: themeScores.length ? this.getAvarageScore(themeScores) : 'Н',
                        prevValue: this.getAvarageScore(this.getScoresOfStudentFromResults(this.prevResults, group.group_id, theme, student.student_id))
                    }
                })
                return {
                        id: student.student_id,
                        name: student.name,
                        ...fields
                    }
            })

            const lastItem = {
                    id: models.reduce((prev, model) => Math.max(model.id, prev), 0) + 1,
                    name: 'Итого (средняя)',
                    final: true
                }
            this.allThemesByGrades[group.grade].map(theme => {
                lastItem[theme] = {
                    value: this.getAvarageScore(this.getScoresOfGroupFromResults(this.results, group.group_id, theme)),
                    prevValue: this.getAvarageScore(this.getScoresOfGroupFromResults(this.prevResults, group.group_id, theme))
                }
            })
            
            return [
                ...models,
                lastItem
            ]
        },
        limitStringLength (str) {
            return StringHelper.limitStringLength(str, 35, '...')
        },
        getAvarageScore (scores) {
            const sum = scores.reduce((a, b) => a + b, 0)
            const avg = (sum / scores.length) || 0
            return parseFloat(avg.toFixed(2))
        },
        getScoresOfStudentFromResults (results, group_id, theme, student_id) { // 
            return results.find(group => group.group_id === group_id)?.items
                            .find(student => student.student_id === student_id)?.scores
                            .find(item => item.theme === theme)?.scores || []
        },
        getScoresOfGroupFromResults (results, group_id, theme) {
            const scores = results.find(group => group.group_id === group_id)?.items
                .map(student => student.scores.find(score => score.theme === theme)?.scores).flat()
                .map(value => value || 0) || []
            
            return scores
        },
        async exportToXlsx () {
            
            this.loading.export = true

            try {
                const workbook = ExcelHelper.createWorkbook()

                Object.keys(this.reportData).forEach(grade => {
                    
                    this.reportData[grade].forEach(group => {

                        const worksheet = ExcelHelper.createWorksheet(workbook, group.name)

                        worksheet.addRow(['№', ...group.headers.map(item => item.text), 'Среднее по ученику'])
                        const userScores = []
                        group.items.forEach((item, itemIndex) => {
                            const row = []
                            
                            row.push(group.items.length > itemIndex + 1 ? itemIndex + 1 : '')

                            row.push(
                                ...group.headers
                                        .map(header => {
                                            const value = item[header.value]

                                            if (typeof value === 'object') {
                                                const isNValue = value?.value === 'Н'
                                                userScores.push(isNValue ? 0 : parseInt(value?.value))
                                                return isNValue ? 'Н' : parseInt(value?.value)
                                            }
                                            return value
                                        })
                            )
                            if (!item.final)
                                row.push(this.getAvarageScore(userScores))
                            
                            worksheet.addRow(row)
                        })

                        worksheet.columns.forEach((column, index) => {
                            column.width = 50

                            if (index === 0)
                                column.width = 5

                            if (index === 1)
                                column.width = 25
                            // On last column
                            if (worksheet.columns.length === index + 1)
                                column.width = 25

                            column.eachCell({ includeEmpty: true }, function(cell, rowNumber) {

                                if (rowNumber === 1) {
                                    cell.font = { bold: true }
                                }
                                
                                cell.border = {
                                    top: {style: 'thin'},
                                    left: {style: 'thin'},
                                    bottom: {style: 'thin'},
                                    right: {style: 'thin'}
                                }
                            })
                        })
                    })
                })
                
                const xlsxData = await workbook.xlsx.writeBuffer()
                const blob = typeof xlsxData === Blob ? xlsxData : new Blob([xlsxData], { type: 'application/xlsx' })
                saveDataAsFile(
                    blob,
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    `${this.exportFileName}.xlsx`
                )
            } catch (e) {
                console.error(e)
                this.$root.$emit('snack-bar', { text: 'Формирование файла прервано ошибкой' })
            } finally {
                this.loading.export = false
            }
        },
        sortBy (array, field) {
            const _array = [...array]
            _array.sort((a, b) => {
                        if (a[field] < b[field]) return -1
                        if (a[field] > b[field]) return 1
                        return 0
                    })
            return _array
        }
    }
}
</script>