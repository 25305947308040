<template>
    <div>
        <div class="d-flex">
            <v-spacer />
            <v-btn
                dark
                color="purple darken-2"
                :loading="loading.export"
                @click.prevent.stop="exportToXlsx"
            >
                Экспорт в xlsx
            </v-btn>
        </div>

        <template v-for="grade in gradesInResults">
            <h4 :key="`header_${grade}`" class="mb-2">Классы {{ grade }}</h4>

            <div :key="`results_${grade}`" class="d-flex flex-wrap">
                <v-card
                    v-for="group in sortBy(results.filter(group => group.grade === grade), 'name')"
                    :key="`card_${group.group_id}`"
                    class="pa-5 mb-5 mr-5"
                    style="width: max-content"
                >
                    <strong class="d-flex mb-4">Класс {{ group.name }}</strong>
                    <v-data-table
                        dense
                        :items-per-page="-1"
                        hide-default-footer
                        calculate-widths
                        :headers="getHeaders()"
                        :items="getModels(group)"
                        class="elevation-1"
                    >
                        <template #[`item.theme`]="{ item }">
                            <span :title="item.theme">{{ limitStringLength(item.theme) }}</span>
                        </template>
                        <template #[`item.value`]="{ item }">
                            <value-cell
                                :value="item.value"
                                :compare-value="item.prevValue"
                                postfix="%"
                            />
                        </template>
                    </v-data-table>
                </v-card>
            </div>
        </template>
    </div>
</template>

<script>
import ValueCell from './ValueCell.vue'
import StringHelper from '@/helpers/StringHelper'
import ExcelHelper from '@/helpers/ExcelHelper'
import { saveDataAsFile } from '@/helpers/File'

export default {
    components: {
        ValueCell
    },
    props: {
        results: { type: Array, required: true },
        prevResults: { type: Array, required: true },
        exportFileName: { type: String, default: 'Сводная по темам' }
    },
    data () {
        return {
            loading: {
                export: false
            }
        }
    },
    computed: {
        gradesInResults () {
            return Array.from(new Set(this.results.map(group => group.grade)))
        }
    },
    methods: {
        getThemesInGrade (grade) {
            return Array.from(
                            new Set(
                                this.results
                                        .filter(group => group.grade === grade)
                                        .map(group => group.items.map(student => student.scores.map(score => score.theme)).flat())
                                        .flat()
                                )
                            )
        },
        limitStringLength (str) {
            return StringHelper.limitStringLength(str, 40, '...')
        },
        getHeaders () {
            return [
                { text: 'Тема', value: 'theme' },
                { text: 'Степень освоения', value: 'value' }
            ]
        },
        getModels(group) {
            if (!group.items.length) return []
            return this.getThemesInGrade(group.grade).map(theme => {
                return {
                    theme,
                    value: this.getAvarageScore(this.getScoresOfGroupFromResults(this.results, group.group_id, theme).map(value => value || 0)),
                    prevValue: this.getAvarageScore(this.getScoresOfGroupFromResults(this.prevResults, group.group_id, theme).map(value => value || 0))
                }
            })
        },
        getAvarageScore (scores) {
            const sum = scores.reduce((a, b) => a + b, 0)
            const avg = (sum / scores.length) || 0
            return parseFloat(avg.toFixed(2))
        },
        getScoresOfGroupFromResults (results, group_id, theme) {
            return results.find(group => group.group_id === group_id)?.items.map(student => student.scores.find(score => score.theme === theme)?.scores).flat() || []
        },
        async exportToXlsx () {
            this.loading.export = true
            try {

            const workbook = ExcelHelper.createWorkbook()
            const worksheet = ExcelHelper.createWorksheet(workbook, 'Сводная по темам')
            const columns = [
                { header: 'Тема', key: 'theme' }
            ]
            const rows = []
            const modelsOfGroups = {}
            this.gradesInResults.forEach(grade => {
                const groups = this.sortBy(this.results.filter(group => group.grade === grade), 'name')
                groups.forEach(group => {

                    columns.push({ header: group.name, key: group.name })
                    modelsOfGroups[group.name] = this.getModels(group)
                    modelsOfGroups[group.name].forEach(model => {

                        let row = rows.find(row => row.theme === model.theme)
                        if (!row) {
                            row = { theme: model.theme }
                            rows.push(row)
                        }

                        row[group.name] = model.value
                    })
                })
            })

            columns.push({ header: 'Средняя по теме', key: 'avarage' })
            worksheet.columns = columns
            rows.forEach(row => {
                const groupNames = this.results.map(group => group.name)
                const scores = groupNames.map(groupName => row[groupName])
                row.avarage = this.getAvarageScore(scores.filter(v => !Number.isNaN(parseInt(v))))
            })
            
            rows.push({})
            const lastRow = { theme: 'Средняя по классу' }
            Object.keys(modelsOfGroups).map(groupName => {
                lastRow[groupName] = this.getAvarageScore(modelsOfGroups[groupName].map(item => item.value))
            })

            rows.push(lastRow)
            rows.forEach(row => worksheet.addRow(row))

            worksheet.columns.forEach((column, index) => {
                column.width = index === 0 ? 50 : 18

                column.eachCell({ includeEmpty: true }, function(cell, rowNumber) {

                    if (rowNumber === 1) {
                        cell.font = { bold: true }
                    }
                    cell.border = {
                        top: {style: 'thin'},
                        left: {style: 'thin'},
                        bottom: {style: 'thin'},
                        right: {style: 'thin'}
                    }
                })
            })

            const xlsxData = await workbook.xlsx.writeBuffer()
            const blob = typeof xlsxData === Blob ? xlsxData : new Blob([xlsxData], { type: 'application/xlsx' })
            saveDataAsFile(
                blob,
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                `${this.exportFileName}.xlsx`
            )
            } catch (e) {
                console.error(e)
                this.$root.$emit('snack-bar', { text: 'Формирование файла прервано ошибкой' })
            } finally {
                this.loading.export = false
            }
        },
        sortBy (array, field) {
            const _array = [...array]
            _array.sort((a, b) => {
                        if (a[field] < b[field]) return -1
                        if (a[field] > b[field]) return 1
                        return 0
                    })
            return _array
        }
    }
}
</script>