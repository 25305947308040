<template>
    <div>
        <v-label>В этом отчёте не учитывается месяц фильтра. Только выбранный год.</v-label>
        <p>Результаты сформированы</p>
        <div class="d-flex">
            <v-spacer />
            <v-btn
                dark
                color="purple darken-2"
                :loading="loading.export"
                @click.prevent.stop="exportToXlsx"
            >
                Экспорт в xlsx
            </v-btn>
        </div>
    </div>
</template>

<script>
import ExcelHelper from '@/helpers/ExcelHelper'
import { saveDataAsFile } from '@/helpers/File'

export default {
    props: {
        results: { type: Array, required: true },
        exportFileName: { type: String, default: 'Сводная по классам' }
    },
    data () {
        return {
            loading: {
                export: false
            }
        }
    },
    computed: {
        gradesInResults () {
            return Array.from(new Set(this.results.map(group => group.grade)))
        }
    },
    methods: {
        getAvarageScore (scores) {
            const sum = scores.reduce((a, b) => a + b, 0)
            const avg = (sum / scores.length) || 0
            return parseFloat(avg.toFixed(2))
        },
        async exportToXlsx () {
            this.loading.export = true

            try {
                const workbook = ExcelHelper.createWorkbook()
                const worksheet = ExcelHelper.createWorksheet(workbook, 'Сводная по классам')

                this.gradesInResults.forEach((grade, gradeI) => {
                    const groups = this.results.filter(group => group.grade === grade)
                    if (!groups?.length) { return }

                    this.sortBy(groups, 'name').forEach((group, groupI) => {
                        
                        const collections = group?.collections?.sort() || []
                        worksheet.addRow([group.name])
                        worksheet.lastRow.eachCell({}, function(cell) {
                            cell.font = { bold: true }
                        })
                        worksheet.addRow(['№', 'ФИО', ...collections.map(n => `Итоговый бал за контрольную ${n} триместр`)])

                        const scoresByCollection = []
                        group.items.forEach((student, index) => {
                            worksheet.addRow([index + 1, student.name, ...collections.map(n => {
                                
                                if (!Array.isArray(scoresByCollection[n]))
                                    scoresByCollection[n] = []

                                scoresByCollection[n].push(student[`job_collection_${n}`] || 0)

                                return student[`job_collection_${n}`] || 'Н'
                            })])
                        })

                        worksheet.addRow()
                        worksheet.addRow([`Итого средняя по ${group.name}`, ...collections.map(n => this.getAvarageScore(scoresByCollection[n]))])
                        if (this.gradesInResults.length - 1 > gradeI && this.gradesInResults.length - 1 > groupI)
                            worksheet.addRow()
                    })
                })

                worksheet.columns?.forEach((column, index) => {
                    column.width = index === 0 ? 25 : 40
                    
                    column.eachCell({ includeEmpty: true }, function(cell) {
                        cell.border = {
                            top: {style: 'thin'},
                            left: {style: 'thin'},
                            bottom: {style: 'thin'},
                            right: {style: 'thin'}
                        }
                    })
                })

                const xlsxData = await workbook.xlsx.writeBuffer()
                const blob = typeof xlsxData === Blob ? xlsxData : new Blob([xlsxData], { type: 'application/xlsx' })
                saveDataAsFile(
                    blob,
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    `${this.exportFileName}.xlsx`
                )
            } catch (e) {
                console.error(e)
                this.$root.$emit('snack-bar', { text: 'Формирование файла прервано ошибкой' })
            } finally {
                this.loading.export = false
            }
        },
        sortBy (array, field) {
            const _array = [...array]
            _array.sort((a, b) => {
                        if (a[field] < b[field]) return -1
                        if (a[field] > b[field]) return 1
                        return 0
                    })
            return _array
        }
    }
}
</script>