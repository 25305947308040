<template>
    <div class="d-flex align-center">
        <strong>{{ value }}{{ postfix }}</strong>
        <span v-if="hasCompareValue">&nbsp;(<span :class="`${diffColor}--text`">{{ diffSymbol }}{{valuesDiff}}%</span>)</span>
    </div>
</template>

<script>
export default {
    props: {
        value: { type: [String, Number], required: true },
        compareValue: { type: Number, default: null },
        postfix: { type: String, default: '' }
    },
    computed: {
        hasCompareValue () {
            return Number.isInteger(this.compareValue) && Number.isFinite(this.compareValue)
        },
        valuesDiff () {
            if (!this.hasCompareValue)
                return 0

            const value = typeof this.value === 'number' ? this.value : 0

            if (this.compareValue === value)
                return 0

            if (this.compareValue === 0)
                return 100

            return Math.round(((value / this.compareValue) * 100) - 100)
        },
        diffSymbol () {
            if (this.valuesDiff > 0)
                return '↑'
            else if (this.valuesDiff < 0)
                return '↓'
            else
                return ''
        },
        diffColor () {
            if (this.valuesDiff > 0)
                return 'green'
            else if (this.valuesDiff < 0)
                return 'red'
            else
                return 'black'
        }
    }
}
</script>